import React, { useRef, useEffect } from "react";
import { Transformer, Image } from "react-konva";
import useImage from "use-image";

const anchors = ["top-left", "top-right", "bottom-left", "bottom-right"];
const anchorStyles = {
  anchorCornerRadius: 10,
  anchorSize: 13,
  anchorFill: "#418AEA",
  anchorStroke: "#fff",
  anchorStrokeWidth: 2,
  rotateAnchorOffset: 20,
};
const KonvaImage = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  stageWidth,
  fullSize = false,
}) => {
  const shapeRef = useRef();
  const trRef = useRef();
  const [image] = useImage(shapeProps.url, "Anonymous");

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <Image
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        {...(fullSize && {
          width: shapeProps.width * 10,
          height: shapeProps.height * 10,
          x: shapeProps.x * 10,
          y: shapeProps.y * 10,
          offsetX: shapeProps.offsetX * 10,
          offsetY: shapeProps.offsetY * 10,
        })}
        image={image}
        draggable
        onDragEnd={(e) => {
          onChange({
            ...e.target.attrs,
          });
        }}
        onTransformEnd={(e) => {
          onChange({
            ...e.target.attrs,
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          enabledAnchors={anchors}
          {...anchorStyles}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default KonvaImage;
