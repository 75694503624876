import React, { useState } from 'react';
import InputBase from './InputBase';

const InputDropDownColor = ({selected, onDataChange, max = 10, min = 0}) =>{
  const [currentPicked, setCurrentPicked] = useState(selected)
  const handleClick = (value)=> {
    let newValue = Math.max(Math.min(currentPicked + value, max), min)
    setCurrentPicked(newValue)
    onDataChange(newValue)
  }
  return (
    <InputBase 
      selected={selected} 
      currentPicked={currentPicked} 
      type={'number'} 
      handleClick={handleClick} 
    />
  );
}
export default InputDropDownColor;
