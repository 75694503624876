import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import style from "../constants/style";
import styled from "styled-components";
import Context from "../Context";
import ShopItem from "./ShopItem"

const Item = ({ design, isMyDesign }) => {
  const [showingDesign, setShowingDesign] = useState(design)
  const { showModalItemDetail } = useContext(Context.Modal);
  return (
    <NavItemSc to={"#"} onClick={() => {
      showModalItemDetail({ ...showingDesign, ...isMyDesign, setShowingDesign })
    }
    }>
      <ShopItem {...showingDesign} isMyDesign={isMyDesign} />
    </NavItemSc>
  );
};
const ShopList = ({ designs, isMyDesign = false, isShowSwitchButton = true }) => {
  return (
    <ItemsHolder>
      {designs.map((x, i) => (
        <Item key={x.designId} design={{ isShowSwitchButton, ...x }} isMyDesign={isMyDesign} />
      ))}
    </ItemsHolder>
  );
};

const ItemsHolder = styled.div`
  display: block;
  text-align: center;
  background: ${style.colors.mainLight3};
  width: auto;
  margin: 10px 10px 0 10px;
  color: ${style.colors.textDark};
`;
const NavItemSc = styled(Link)`
  display: inline-block;
  width: calc(100% - 16px);
  @media (min-width: 321px) {
    width: calc(50% - 16px);
  }
  @media (min-width: 426px) {
    width: calc(33% - 16px);
  }
  @media (min-width: 600px) {
    width: calc(25% - 16px);
  }
  @media (min-width: 900px) {
    width: calc(20% - 16px);
  }
  margin: 8px;
  text-align: center;
  background: ${style.colors.mainDark2};
`;
export default ShopList;
