import React, { useEffect, useContext, useState, useRef } from "react";
import ShopList from "../components/ShopList";
import Banner from "../components/Banner";
import Context from "../Context";

const Home = () => {
  const { callApi } = useContext(Context.Modal);
  const bottomRef = useRef(null);
  const [designs, setDesigns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastDesignId, setLastDesignId] = useState("");
  const [ts, setTs] = useState(Number(new Date()));
  const getMoreDesign = async (option = {}) => {
    if (lastDesignId === "No more design") return;
    setIsLoading(true);
    const designResults = (await callApi("designs", { lastDesignId }, option))
      .data;
    if (designResults.length) {
      setLastDesignId(designResults.slice(-1)[0].designId);
    } else {
      setLastDesignId("No more design");
    }
    setDesigns((oldDesign) => [...oldDesign, ...designResults]);
    setIsLoading(false);
  };
  useEffect(() => {
    const onScroll = (e) => {
      if (!bottomRef.current) return;
      const isBottom =
        parseInt(bottomRef.current.getClientRects()[0].bottom) <
        parseInt(window.innerHeight + 10);
      if (isBottom && !isLoading) {
        setTs(Number(new Date()));
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    (async () => {
      if (!isLoading) {
        await getMoreDesign({ notShowLoading: true });
      }
    })();
    // eslint-disable-next-line
  }, [ts]);
  return (
    <>
      <Banner />
      <ShopList designs={designs} isShowSwitchButton={false} />
      <div ref={bottomRef} style={{ position: "absolute", bottom: 0 }} />
      {isLoading && (
        <div style={{ textAlign: "center" }}>
          <img
            style={{ height: "15vw" }}
            src="/loading.gif"
            alt="Loading More..."
          />
        </div>
      )}
    </>
  );
};
export default Home;
