const tee = {
    colors: {
        White: 'White',
        Red: 'Red',
        Green: 'Green',
        Blue: 'Blue',
        Black: 'Black',
        Yellow: 'Yellow'
    }
}
export {tee}