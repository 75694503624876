const updateAddress = async (data, callApi, setState, type = "users") => {
  const {
    isDefault,
    oldString,
    currentString,
    firstName,
    lastName,
    currentNumber: number,
    currentDistrict: district,
    currentProvince: province,
    currentSubDistrict: subDistrict,
    currentZipcode: zipcode,
    orderId,
  } = data
  const response = await callApi(`${type}/updateAddress`, {
    orderId,
    oldString,
    currentString,
    address: {
      isDefault,
      firstName,
      lastName,
      number,
      district,
      province,
      subDistrict,
      zipcode
    }
  })
  setState(response.data)
  return response.data
}
const orderUpdateAddress = async (data, callApi, setState) => {
  const {
    isDefault,
    oldString,
    currentString,
    firstName,
    lastName,
    currentNumber: number,
    currentDistrict: district,
    currentProvince: province,
    currentSubDistrict: subDistrict,
    currentZipcode: zipcode
  } = data
  const response = await callApi("users/updateAddress", {
    oldString,
    currentString,
    address: {
      isDefault,
      firstName,
      lastName,
      number,
      district,
      province,
      subDistrict,
      zipcode
    }
  })
  setState(response.data)
  return response.data
}
export {
  updateAddress,
  orderUpdateAddress
}