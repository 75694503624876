import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Padding = styled.div`
  position: relative;
  display: block;
  height: 30vw;
  max-height: 270px;
  margin-top: -20px;
  margin-bottom: 20px;
`;
const Holder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;
const Main = styled.div`
  width: 100%;
  display: block;
  height: 30vw;
  max-height: 270px;
  position: relative;
`;
const Image = styled.div`
  height: 100%;
  background-position: center;
  background-image: url("${(props) => props.src}");
  background-size: cover;
`;
const Right = styled.span`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`;
const Left = styled.span`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`;
const Banner = () => {
  const [currentImage, setCurrentImage] = useState(0);
  useEffect(() => {
    const autoSlide = setInterval(() => {
      setCurrentImage((x) => (x + 1) % 6);
    }, 3000);
    return () => clearInterval(autoSlide);
  }, []);
  return (
    <>
      <Padding />
      <Holder>
        <Main>
          <Right onClick={() => setCurrentImage((x) => (x + 1) % 6)}>
            {">"}
          </Right>
          <Left onClick={() => setCurrentImage((x) => (x + 7) % 6)}>{"<"}</Left>
          {Array(7)
            .fill(1)
            .map((x, i) => (
              <Image
                key={i}
                hidden={i !== currentImage}
                src={`http://react-responsive-carousel.js.org/assets/${
                  i + 1
                }.jpeg`}
              />
            ))}
        </Main>
      </Holder>
    </>
  );
};
export default Banner;
//
