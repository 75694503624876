import React from "react";
import styled from "styled-components";

const ModalBase = ({ hide, noBackground, children, isFullWidth }) => {
  return (
    <ModalBackGround>
      <ModalHolder>
        {noBackground ? (
          children
        ) : (
          <ModalDataHolder isFullWidth={isFullWidth}>
            <ClosingBtn onClick={() => hide()}>&#10006;</ClosingBtn>
            <MockModalData>{children}</MockModalData>
          </ModalDataHolder>
        )}
      </ModalHolder>
    </ModalBackGround>
  );
};
export default ModalBase;

const ModalBackGround = styled.div`
  display: block;
  position: fixed;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  heigth: 100%;
  z-index: 9999;
`;
const ModalHolder = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 20px;
  ${"" /* margin: auto; */}
`;
const ModalDataHolder = styled.div`
  display: ${(props) => (props.isFullWidth ? "block" : "inline-block")};
  box-shadow: 0 0 150px 50px black;
  padding: 5%;
  max-width: calc(900px - 10%);
  margin: auto;
  background: white;
  position: relative;
  border-radius: 15px;
`;
const MockModalData = styled.div`
  position: relative;
  width: 100%;
  max-height: 600px;
  display: block;
  overflow: scroll;
`;
const ClosingBtn = styled.div`
  position: absolute;
  right: -7px;
  top: -7px;
  background: red;
  border-radius: 50%;
  padding-left: 2px;
  padding-top: 3px;
  width: 23px;
  height: 22px;
  cursor: pointer;
  z-index: 9999;
`;