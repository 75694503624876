import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import style from "../constants/style";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import Context from "../Context";

// background: ${style.colors.mainDark3};
const NavbarHolder = styled.div`
  text-align: center;
  background: cornflowerblue;
  color: ${style.colors.textWhite};
  font-weight: 900;
  color: white;
  font-size: 1.2em;
  position: fixed;
  z-index: 9998;
  top: 0;
  width: 100%;
  ${style.css.lightShadow}
`;
// height: 42px;
const NavbarPadding = styled.div`
  width: 100%;
  height: 42px;
`;
const NavItemSc = styled.div`
  display: inline-block;
  padding: 10px;
  text-align: center;
  position: relative;
  ${(props) => props.isSelected && `background: ${style.colors.mainDark2};`}
`;

const SlideLinkCss = (isShow) => {
  return {
    display: "block",
    transition: "padding .3s, opacity .1s",
    height: isShow ? `auto` : `0`,
    paddingTop: isShow ? `10px` : `0`,
    paddingBottom: isShow ? `10px` : `0`,
    opacity: isShow ? 1 : 0,
  };
};
const NavItem = ({ id, url, name, isSelected, handleClick }) => {
  return (
    <div style={{ display: "contents", flex: 1, maxWidth: "100px" }}>
      <Link to={url} onClick={handleClick}>
        <NavItemSc isSelected={isSelected}>{name}</NavItemSc>
      </Link>
    </div>
  );
};
const Navbar = () => {
  const { showModalLogin } = useContext(Context.Modal);
  const history = useNavigate();
  const { pathname } = useLocation();
  const [showAccount, setShowAccount] = useState(false);
  const [navSelected, setNavSelected] = useState(
    window.location.pathname.split("/")[1]
  );

  useEffect(() => {
    setNavSelected(pathname.split("/")[1]);
  }, [pathname]);
  useEffect(() => {
    showAccount
      ? setNavSelected("account")
      : setNavSelected(window.location.pathname.split("/")[1]);
  }, [showAccount]);
  return (
    <>
      <NavbarPadding />
      <NavbarHolder>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              margin: "3px",
              verticalAlign: "middle",
              flex: 1,
              maxWidth: "150px",
              backgroundImage: `url("logo.png")`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
            onClick={() => history("/")}
          ></div>
          <NavItem
            id="custom"
            url="/custom"
            name="ออกแบบ"
            isSelected={"custom" === navSelected}
            handleClick={() => setShowAccount(false)}
          />
          <NavItem
            id="cart"
            url="/cart"
            name="ตะกร้า"
            isSelected={"cart" === navSelected}
            handleClick={() => setShowAccount(false)}
          />
          <NavItem
            id="profile"
            url={"#"}
            name={localStorage.accessToken ? "บัญชี" : "เข้าระบบ"}
            isSelected={
              showAccount ||
              ["design", "profile", "orders"].includes(navSelected)
            }
            handleClick={() => {
              if (localStorage.accessToken) {
                setShowAccount((x) => !x);
              } else {
                showModalLogin({ isShow: true })
              }
            }}
          />
        </div>
        {[
          ["profile", "โปรไฟล์"],
          ["orders", "คำสั่งซื้อ"],
          ["design", "แบบของฉัน"],
          ["logout", "ออกจากระบบ"],
        ].map((item) => (
          <Link
            to={"/" + item[0]}
            style={SlideLinkCss(showAccount)}
            onClick={() => setShowAccount(false)}
            key={item[0]}
          >
            {showAccount && (
              <NavItemSc
                style={{
                  width: "100%",
                  padding: "10px 0",
                  ...SlideLinkCss(showAccount),
                }}
              >
                {item[1]}
              </NavItemSc>
            )}
          </Link>
        ))}
      </NavbarHolder>
    </>
  );
};
export default Navbar;
