import React, { useEffect, useContext, useState, useRef } from "react";
import ShopList from "../components/ShopList";
// import Banner from "../components/Banner";
import Context from "../Context";

const Design = () => {
  const { callApi } = useContext(Context.Modal);
  const bottomRef = useRef(null);
  const [designs, setDesigns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastDesignId, setLastDesignId] = useState("");
  const [ts, setTs] = useState(Number(new Date()));
  const getMoreDesign = async (option = {}) => {
    if (lastDesignId === "No more design") return;
    setIsLoading(true);
    const designResults = (
      await callApi("designs/my", { lastDesignId }, option)
    ).data;
    if (designResults.length) {
      setLastDesignId(designResults.slice(-1)[0].designId);
    } else {
      setLastDesignId("No more design");
    }
    setDesigns((oldDesign) => [...oldDesign, ...designResults]);
    setIsLoading(false);
  };
  useEffect(() => {
    let touchstartY = 0;

    const handleGestureStart = (e) => {
      touchstartY = e.changedTouches[0].screenY;
    };
    const isBottom = () =>
      parseInt(bottomRef.current.getClientRects()[0].bottom) <
      parseInt(window.innerHeight + 10);

    const handleGestureEnd = (e) => {
      if (e.changedTouches[0].screenY + 100 < touchstartY) {
        if (isBottom() && lastDesignId !== "No more design") {
          onScroll();
        }
      }
    };

    const onScroll = () => {
      if (!bottomRef.current) return;
      if (isBottom() && !isLoading) {
        setTs(Number(new Date()));
      }
    };
    window.addEventListener("scroll", onScroll);
    window.addEventListener("touchstart", handleGestureStart);
    window.addEventListener("touchend", handleGestureEnd);
    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("touchstart", handleGestureStart);
      window.removeEventListener("touchend", handleGestureEnd);
    };
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    (async () => {
      if (!isLoading && localStorage.accessToken) {
        await getMoreDesign({ notShowLoading: true });
      }
    })();
    // eslint-disable-next-line
  }, [ts]);
  return (
    <>
      {designs.length ? (
        <>
          <ShopList designs={designs} isMyDesign={true}  isShowSwitchButton={false} />
          <div ref={bottomRef} style={{ position: "absolute", bottom: 0 }} />
          {isLoading && (
            <h3 style={{ textAlign: "center" }}>Loading More...</h3>
          )}
        </>
      ) : (
        <div style={{ textAlign: "center" }}>ไม่พบแบบที่คุณสร้างไว้</div>
      )}
    </>
  );
};
export default Design;
