import React, { useContext } from "react";
import styled from "styled-components";
import Context from "../Context";
import ShopItem from "./ShopItem"
import { sum, sumPxQ } from "../util"

const SmallDetailItem = ({ order }) => {
  const { config } = useContext(Context.General);
  const totalPrice = sumPxQ(order.items, config.price)
  return (
    <FlexHolder>
      <FlexChild>
        <FlexImage>
          <ShopItem previewF={order.previewF} color={order.items[0].color} isShowSwitchButton={false} facingFront={true} />
        </FlexImage>
        <FlexImage>
          <ShopItem previewB={order.previewB} color={order.items[0].color} isShowSwitchButton={false} facingFront={false} />
        </FlexImage>
      </FlexChild>
      <FlexChildR>
        <div>
          <DetailContainerSc>
            {order.items.map((item, i) => (
              <div key={i}>
                <div>ขนาด {item.size}</div>
                <div>{item.quantity} ตัว</div>
                <div>฿{item.quantity * (item.price + config.price)}</div>
              </div>
            ))}
          </DetailContainerSc>
        </div>
      </FlexChildR>
      <span
        style={{
          position: "absolute",
          top: "calc(100% - 25px)",
          right: "10px",
        }}
      >
        <b>รวม: {totalPrice}</b>
      </span>
    </FlexHolder>
  );
};
export default SmallDetailItem;



const FlexHolder = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;
const FlexChild = styled.div`
  flex: 2;
  display: flex;
`;
const FlexChildR = styled.div`
  flex: 1;
  position: relative;
  margin: auto;
  text-align: left;
  padding: 0 10px 10px 10px;
`;
const FlexImage = styled.div`
  flex: 1;
  position: relative;
  margin: auto;
  text-align: left;
  padding: 0 10px 0 0;
`;
const DetailContainerSc = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
`;
