import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Context from "../Context";
import ShopItem from "./ShopItem"
import { tee } from "../constants/stock";
import InputDropDownColor from "./InputDropDownColor";
import InputDropDownText from "./InputDropDownText";
import InputInteger from "./InputInteger";
import Button from "./Button";
import style from "../constants/style";
import { sum, sumPxQ } from "../util"

const CartItems = ({ hide, setSumArray, setSumPrice, index, design }) => {
  const { modalItemDetail, callApi } = useContext(Context.Modal);
  const { config } = useContext(Context.General);
  const [designShown, setDesignShow] = useState(design ? design : modalItemDetail);
  const [tempColor, setTempColor] = useState("");
  const [currentColor, setCurrentColor] = useState(designShown?.items?.[0]?.color || designShown.color || "White");
  const [selectedItems, setSelectedItem] = useState(designShown?.items || []);
  const [designPrice, setDesignPrice] = useState("xxx");
  const isCartPage = useMemo(() => Boolean(design), [design]);
  const [price, setPrice] = useState(0)
  const [isPublic, setIsPublic] = useState(false)
  const [imageName, setImageName] = useState("")
  const [designId, setDesignId] = useState("")

  useEffect(() => {
    if (modalItemDetail.designId) {
      setDesignShow(modalItemDetail)
      setSelectedItem([{ ...modalItemDetail, quantity: 1, size: "M" }] || [])
    }
  }, [modalItemDetail])
  useEffect(() => {
    setDesignPrice(sumPxQ(selectedItems, config.price))
    if (selectedItems.length) {
      setPrice(selectedItems[0]?.price)
      setIsPublic(selectedItems[0]?.isPublic)
      setImageName(selectedItems[0]?.imageName)
      setDesignId(selectedItems[0]?.designId)
    }
  }, [selectedItems, config])
  useEffect(() => {
    setSumArray && setSumArray(oldArray => {
      oldArray[index] = designPrice
      setSumPrice(sum(oldArray))
      return oldArray
    })
  }, [designPrice])
  const handleChange = (i, type, val) => {
    const newSelectedItems = JSON.parse(JSON.stringify(selectedItems));
    newSelectedItems[i][type] = val;
    setSelectedItem(newSelectedItems);
    setDesignPrice(sumPxQ(newSelectedItems, config.price));
    isCartPage && updateCart(newSelectedItems);
  };
  const updateCart = async (designs) => {
    const cleanedDesigns = designs.map(({ color, price, quantity, size }) => {
      return {
        color,
        price,
        quantity,
        size,
      }
    });
    await callApi("orders/cart", {
      newCart: {
        [designShown.designId]: {
          items: cleanedDesigns,
          imageName: designShown.imageName,
          designId: designShown.designId,
          previewF: designShown.previewF,
          previewB: designShown.previewB,
        },
      },
    });
  };
  const addTee = () => {
    let newSelectedItems = JSON.parse(JSON.stringify(selectedItems));
    newSelectedItems = [
      ...newSelectedItems,
      newSelectedItems[newSelectedItems.length - 1],
    ];
    setSelectedItem(newSelectedItems);
    setDesignPrice(sumPxQ(newSelectedItems, config.price));
    isCartPage && updateCart(newSelectedItems);
  };
  return (
    <FlexHolder>
      <FlexChild>
        <ShopItem
          {...designShown}
          isShowSwitchButton={true}
          imageName={designShown.imageName}
          price={undefined}
          name={modalItemDetail.text}
          color={tempColor || currentColor}
        />
      </FlexChild>
      <FlexChildR>
        {selectedItems.map((item, i) => (
          <div key={i}>
            {designShown.isMyDesign ? <>
              <div style={{ display: "block", marginTop: "5px" }}>
                <input
                  display="block"
                  type="text"
                  style={{
                    fontSize: "1rem",
                    textAlign: "center",
                    margin: "auto",
                  }}
                  defaultValue={item.imageName}
                  onChange={(e) => setImageName(e.currentTarget.value)}
                />
              </div>
              <InputDropDownColor
                center={true}
                setTempColor={setTempColor}
                setCurrentColor={setCurrentColor}
                items={tee.colors}
                selected={item.color || "White"}
                onDataChange={(val) => {
                  handleChange(i, "color", val);
                }}
                style={{ margin: "auto !important" }}
              />
              <InputDropDownText
                optionWidth={"auto"}
                items={["เปิดสาธารณะ", "ซ่อน"]}
                selected={item.isPublic ? "เปิดสาธารณะ" : "ซ่อน"}
                onDataChange={(val) => {
                  setIsPublic(val);
                }}
              />
              {config.isFreeTrade === config.isFreeTrade && <>
                <div style={{ display: "block" }}>
                  <div style={{ display: "inline", minWidth: "57px", verticalAlign: "top" }}>
                    {"ราคาแบบ ฿"}
                  </div>
                  <input
                    type="integer"
                    maxLength={3}
                    style={{
                      fontSize: "1rem",
                      width: "2rem",
                      textAlign: "center",
                      margin: "auto",
                    }}
                    defaultValue={item.price}
                    onChange={(e) => setPrice(parseInt(e.currentTarget.value) || 0)}
                  />
                  <br />
                  <div style={{ display: "inline-flex", minWidth: "57px" }}>
                    {"ราคาขาย ฿"}
                    {(price) + config.price}
                  </div>
                </div>
              </>}
            </> : <>
              {/* not my design page */}
              <InputDropDownColor
                setTempColor={setTempColor}
                setCurrentColor={setCurrentColor}
                items={tee.colors}
                selected={item.color || "White"}
                onDataChange={(val) => {
                  handleChange(i, "color", val);
                }}
              />
              <InputDropDownText
                minWidth={57}
                items={["S", "M", "L", "XL"]}
                selected={item.size}
                onDataChange={(val) => {
                  handleChange(i, "size", val);
                }}
              />
              <InputInteger
                minWidth={57}
                selected={item.quantity || 0}
                onDataChange={(val) => {
                  handleChange(i, "quantity", val);
                }}
              />
              <div style={{ display: "inline-flex", minWidth: "57px" }}>
                <div style={{ marginTop: "25%" }}>
                  {" "}
                  ฿{(item.quantity || 0) * (item.price + config.price)}
                </div>
              </div>
            </>}
          </div>
        ))}
        {designShown.isMyDesign ? <>
          <Button
            handleClick={async () => {
              await callApi("designs/update", { designId, design: { color: currentColor, isPublic: isPublic !== "ซ่อน", price, imageName } })
              modalItemDetail.setShowingDesign(old => { return { ...old, color: currentColor, isPublic: isPublic !== "ซ่อน", price, imageName, isShowSwitchButton: false } })
              hide && hide();
            }}
          >
            บันทึก
          </Button>
          <Button
            handleClick={() => {
              console.log("Delete", selectedItems);
              hide && hide();
            }}
          >
            ลบแบบ
          </Button>
        </> : <>
          {/* //cart or home page */}
          <div>
            <span>รวม: {designPrice}</span>
          </div>
          <div>
            <AddBtn onClick={addTee}>
              <span role="img" aria-label="sth">
                &#10133;
              </span>
            </AddBtn>
          </div>
          {!isCartPage && (
            <Button
              handleClick={() => {
                updateCart(selectedItems);
                hide && hide();
              }}
            >
              ใส่ตระกร้า
            </Button>
          )}
        </>}
      </FlexChildR>
    </FlexHolder>
  );
};
export default CartItems;

const FlexHolder = styled.div`
  @media (min-width: 600px) {
    display: flex;
  }
  max-height: 90vh;
  overflow: scroll;
  width: 100%;
`;
const FlexChild = styled.div`
  width: 100%;
`;
const FlexChildR = styled.div`
  min-width: 290px;
  width: 100%;
`;
const AddBtn = styled.div`
  cursor: pointer;
  border: solid ${style.colors.mainDark1} 3px;
  display: inline-block;
  border-radius: 50%;
  padding: 0 5px 2px 0;
  width: 24px;
  height: 26px;
  margin: 10px;
  color: white;
  position: relative;
  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  background-color: ${style.colors.mainLight1};
  :hover {
    background-color: ${style.colors.mainLight2};
  }
  :active {
    background-color: ${style.colors.mainLight3};
  }
`;