import React, { useContext } from "react";
import ModalBase from "./ModalBase";
import Context from "../Context";
// import CartItem from "./CartItem";

const ModalItemDetail = () => {
  const { hideModal } = useContext(Context.Modal);
  return (
    <ModalBase
      hide={hideModal}
      isFullWidth={true}
      noBackground={true}
    >
      {/* https://cssbud.com/gif/tri-spinner/ */}
      <img
        src="/loading2.gif"
        alt="Loading"
        width={"100%"}
        style={{ maxWidth: "300px" }}
      ></img>
    </ModalBase>
  );
};
export default ModalItemDetail;
