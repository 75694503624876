import React from 'react';
const AddressString = ({ firstName, lastName, number, subDistrict, district, province, zipcode }) => {
  return (<>
    {firstName || ""} {lastName || ""}< br />
    {number || ""}{number && < br />}
    {subDistrict || ""} {district || ""}< br />
    {province || ""} {zipcode || ""}< br />
  </>)
}
export default AddressString;
