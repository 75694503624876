/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Cart from "./pages/Cart";
import Orders from "./pages/Orders";
import OrderDetail from "./pages/OrderDetail";
import Design from "./pages/Design";
import Custom from "./pages/Custom";
import Profile from "./pages/Profile";
import Logout from "./pages/Logout";
import styled from "styled-components";
import ModalItemDetail from "./components/ModalItemDetail";
import ModalLoading from "./components/ModalLoading";
import ModalAddress from "./components/ModalAddress";
import Context from "./Context";
import style from "./constants/style";
// import callApi from "./util/callApi";
import axios from "axios";
import ModalLogin from "./components/ModalLogin";
import Navbar from "./components/Navbar";

function App() {
  //modal function --------------------------------------------------
  const [modalItemDetail, setModalItemDetail] = useState({ isShow: false });
  const showModalItemDetail = (detail) => setModalItemDetail({ ...detail, isShow: true });

  const [modalAddress, setModalAddress] = useState({ isShow: false });
  const showModalAddress = (detail) => {
    setModalAddress({ ...detail, isShow: true })
  };

  const [modalLoading, setModalLoading] = useState({ isShow: false });
  const [modalLogin, setModalLogin] = useState({ isShow: false });
  const showModalLogin = (detail) => setModalLogin({ ...detail, isShow: true });

  const hideModal = () => {
    setModalItemDetail({ isShow: false })
    setModalLoading({ isShow: false })
    setModalLogin({ isShow: false })
    setModalAddress({ isShow: false })
  };
  //modal function --------------------------------------------------

  const [config, setConfig] = useState({});
  const [cartState, setCartState] = useState({});
  const [profileState, setProfileState] = useState({});
  const callApi = async (path, body, options = {}) => {
    const url = "https://asia-east1-tees-b9a74.cloudfunctions.net/"
    // const url = "http://localhost:5001/tees-b9a74/asia-east1/"
    !options.notShowLoading && setModalLoading({ isShow: true });
    try {
      var data = JSON.stringify(body);
      var config = {
        method: body ? "post" : "get",
        url: url + path,
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.accessToken,
        },
        data,
      };
      const response = await axios(config);
      console.log(body ? "post" : "get", config.url, body, response.data);
      !options.notShowLoading && setModalLoading({ isShow: false });
      return response;
    } catch (err) {
      !options.notShowLoading && setModalLoading({ isShow: false });
      return { status: err.response.status || 500, data: err.response.data };
    }
  };
  const promiseAllCallApi = async (callApis, options = {}) => {
    !options.notShowLoading && setModalLoading({ isShow: true });
    try {
      !options.notShowLoading && setModalLoading({ isShow: false });
      return await Promise.all(callApis);
    } catch (err) {
      !options.notShowLoading && setModalLoading({ isShow: false });
      return [];
    }
  }
  useEffect(() => {
    if (modalItemDetail.isShow || modalLoading.isShow || modalAddress.isShow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [modalItemDetail, modalLoading, modalAddress]);
  useEffect(async () => {
    const loginOldUser = async () => {
      if (localStorage.getItem("accessToken")) {
        const userResult = await callApi("users/", undefined, { notShowLoading: true });
        if (userResult.status === 401) {
          window.localStorage.removeItem("accessToken");
        } else if (userResult.status === 200) {
          setProfileState(userResult.data);
        }
      }
    }
    const getConfig = async () => {
      const config = await callApi("config/", undefined, { notShowLoading: true });
      setConfig(config.data)
    }
    await promiseAllCallApi([loginOldUser(), getConfig()])
  }, []);
  return (
    <Context.Modal.Provider
      value={{
        hideModal,
        modalItemDetail,
        showModalItemDetail,
        modalLogin,
        showModalLogin,
        callApi,
        modalAddress,
        showModalAddress
      }}
    >
      <Context.General.Provider value={{ config, setConfig }}>
        <Context.Cart.Provider value={{ cartState, setCartState }}>
          <Context.Profile.Provider value={{ profileState, setProfileState }}>
            {modalItemDetail.isShow && <ModalItemDetail />}
            {modalLogin.isShow && <ModalLogin />}
            {modalLoading.isShow && <ModalLoading />}
            {modalAddress.isShow && <ModalAddress />}
            <Navbar />
            <BodySc>
              <SmallBodySc>
                <Routes>
                  <Route exact path="" element={<Home />} />
                  <Route exact path="/custom" element={<Custom />} />
                  <Route exact path="/design" element={<Design />} />
                  <Route exact path="/cart" element={<Cart />} />
                  <Route exact path="/orders" element={<Orders />} />
                  <Route
                    exact
                    path="/orders/:orderId"
                    element={<OrderDetail />}
                  />
                  <Route exact path="/profile" element={<Profile />} />
                  <Route exact path="/logout" element={<Logout />} />
                </Routes>
              </SmallBodySc>
            </BodySc>
          </Context.Profile.Provider>
        </Context.Cart.Provider>
      </Context.General.Provider >
    </Context.Modal.Provider>
  )
}
export default App;

const BodySc = styled.div`
  background-color: ${style.colors.lightGray};
  min-height: calc(100vh - 42px);
  position: absolute;
  top: 42px;
  width: 100%;
`;
const SmallBodySc = styled.div`
  background-color: white;
  padding: 20px 10px;
  min-height: calc(100vh - 42px - 40px);
  max-width: 900px;
  position: relative;
  margin: auto;
  height: 100%;
  ${style.css.lightShadow}
`;
