import Tabs from "../components/Tabs";
import style from "../constants/style";
import styled from "styled-components";
import React, { useState, useRef, useContext } from "react";
import Context from "../Context";
import { useNavigate } from "react-router-dom";
import DesignTee from "../components/DesignTee";

const DesignPlate = () => {
  // const [newImageF, = useState(null);
  // const [newImageB, = useState(null);
  const [isFront, setIsFront] = useState(true)
  const [isShownBack, setIsShownBack] = useState(false)
  const [imageName, setImageName] = useState("Testa")
  const { callApi, showModalLogin } = useContext(Context.Modal);
  const childRefF = useRef();
  const childRefB = useRef();
  const history = useNavigate();

  const saveBothSide = async () => {
    const [previewF, printViewF] = childRefF.current?.saveImage() || ["", ""]
    const [previewB, printViewB] = childRefB.current?.saveImage() || ["", ""]
    // const [previewF, printViewF] = ["", ""]
    // const [previewB, printViewB] = ["", ""]

    if (!localStorage.accessToken) {
      localStorage.setItem(`dataUri_F`, previewF);
      localStorage.setItem(`dataUri_full_F`, printViewF);
      localStorage.setItem(`dataUri_B`, previewB);
      localStorage.setItem(`dataUri_full_B`, printViewB);
      localStorage.setItem(`imageName`, imageName);
      showModalLogin({ redirect: "custom", isShow: true })
      return;
    }

    await callApi("designs/create", {
      dataUri_F: previewF,
      dataUri_B: previewB,
      dataUriFull_F: previewF,
      dataUriFull_B: previewB,
      imageName,
    });
    history("/cart");
  }
  const comnonDesignProps = {
    saveBothSide,
    setIsFront,
    setIsShownBack,
    imageName,
    setImageName,
  }
  return (
    <DesignHolderSc>
      <DesignSc>
        <div style={{ display: isFront ? "block" : "none" }}>
          <DesignTee {...comnonDesignProps} showUpload={isFront} ref={childRefF} bg={"font.png"} imgItems={[1]}></DesignTee>
        </div>
        {isShownBack &&
          <div style={{ display: !isFront ? "block" : "none" }}>
            <DesignTee {...comnonDesignProps} showUpload={!isFront} ref={childRefB} bg={"back.png"} imgItems={[1, 2]}></DesignTee>
          </div>
        }
      </DesignSc>
    </DesignHolderSc>
  );
};

export default DesignPlate;

const DesignHolderSc = styled.div`
  padding: 5px;
`;
const DesignSc = styled.div`
  text-align: center;
  background: white;
  color: ${style.colors.textDark};
`;
