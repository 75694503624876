import React, { useContext, useEffect } from "react";
import ModalBase from "./ModalBase";
import Context from "../Context";

const ModalLogin = () => {
  const { modalLogin, hideModal } = useContext(Context.Modal);
  useEffect(() => {
    localStorage.setItem("redirect", window.location.pathname); // eslint-disable-next-line
    console.log("🚀 ~ file: ModalLogin.js ~ line 9 ~ useEffect ~ window.location", window.location.origin)
  }, []);
  return (
    <ModalBase hide={hideModal} isFullWidth={true}>
      <div style={{ textAlign: "center", margin: "auto", padding: "20% 0" }}>
        <img
          src="/btn_login_base.png"
          alt="Line Login"
          onClick={
            () =>
              (window.location = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1656854596&redirect_uri=${window.location.href}profile&state=state&scope=profile%20openid%20email`)
          }
        />
      </div>
    </ModalBase>
  );
};
export default ModalLogin;
