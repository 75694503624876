export const tsToStr = parseInt(
  new Date()
    .toISOString()
    .slice(-22)
    .replace(/(\.|-|:|T|Z)/g, "")
).toString(36);
export const sumPxQ = (arr, basePrice) => {
  const result = arr.reduce((r, x) => (r += x.quantity * (x.price + basePrice)), 0)
  if (parseInt(result)) {
    return result
  } else {
    return "xxx"
  }
}
export const sum = (arr) => arr.reduce((r, x) => (r += x), 0)
export const addressToHtmlString = ({ address, subDistrict, district, province, zipcode }) => {
  return `${address}<br>
  ${subDistrict}${district}<br/>
  ${province}${zipcode}`
}
