const image = {
    template: {
        width: 450,
        height: 550,
        hRatio: 550/450,
        wRatio: 450/550
    },
    a4: {
        width: 210,
        height: 297,
        hRatio: 297/210,
        wRatio: 210/297
    }
}
export default image