import React, { useState } from "react";
import styled from "styled-components";
import InputBase from "./InputBase";
import SingleDropdownOptionSc from "./SingleDropdownOptionSc";

const Option = styled(SingleDropdownOptionSc)`
  width: ${({ width }) => width || "35px"};
  :first-child {
    border-radius: 5px 5px 0 0;
  }
  :last-child {
    border-radius: 0 0 5px 5px;
  }
`;
const InputDropDownText = ({ items, selected, onDataChange, optionWidth }) => {
  const [index, setIndex] = useState(items.indexOf(selected) || 0);
  const [currentPicked, setCurrentPicked] = useState(items[index] || "");
  const handleClick = (text) => {
    const newIndex = items.indexOf(text);
    setIndex(newIndex);
    setCurrentPicked(items[newIndex]);
    onDataChange(items[newIndex]);
  };
  return (
    <InputBase

      items={items}
      selected={selected}
      currentPicked={currentPicked}
    >
      {items.map((item) => (
        <Option
          width={optionWidth}
          onClick={() => handleClick(item)}
          value={item}
          key={item}>
          {item}
        </Option>
      ))}
    </InputBase>
  );
};
export default InputDropDownText;
