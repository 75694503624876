import React, { useState } from 'react';
import styled from 'styled-components';
import TEMP_DropDownBase from './InputBase';
import SingleDropdownOptionSc from './SingleDropdownOptionSc';

const Option = styled(SingleDropdownOptionSc)`
  ${props => `background-color: ${props.value}`};
  width: 20px;
  border-radius: 5px;
  margin: 5px;
  :hover {
    ${props => `background-color: ${props.value}`};
    ${props => `border: solid ${props.value} 1px;`};
    
  }
`
const InputBase = styled(TEMP_DropDownBase)`
  vertical-align: top;
`
const ColorHolder = styled.div`
  width: 56px;
  border-radius: 5px;
  border: solid 1px black;
  background-color: white;
  margin-top: -1px;
  margin-left: -1px;
`
const InputDropDownColor = ({center, items, selected, onDataChange, setTempColor, setCurrentColor}) =>{
  const [currentPicked, setCurrentPicked] = useState(items[selected] || '')
  const handleClick = (text)=> {
    setCurrentPicked(items[text])
    onDataChange(items[text])
    setCurrentColor(items[text])
  }
  return (
  <InputBase center={center} items={items} selected={selected} currentPicked={currentPicked} bgColor={items[selected]}>
    <ColorHolder>
      {Object.keys(items).map( item => 
        <Option 
          onClick={()=>handleClick(item)} 
          value={items[item]} 
          onMouseOver={()=>setTempColor(items[item])}
          onMouseLeave={()=>setTempColor('')}
          key={item}/>
      )}
    </ColorHolder>
  </InputBase>
  );
}
export default InputDropDownColor;
