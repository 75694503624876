import React, { useState, useContext } from "react";
import styled from "styled-components";
import Context from "../Context";

const ShopItem = ({
  imageName,
  color,
  width,
  previewF,
  previewB,
  price,
  facingFront = true,
  isShowSwitchButton = true
}) => {
  const [isFront, setIsFront] = useState(facingFront)
  const [frontLoading, setFrontLoading] = useState(true)
  const [backLoading, setBackLoading] = useState(true)
  const { config } = useContext(Context.General);
  return (
    <>
      <MainItemSc style={{ borderColor: "red", width: `${width}px` }}>
        {isShowSwitchButton &&
          <SwitchSideSc onClick={() => setIsFront(old => !old)} />
        }
        {isFront ?
          <TeeSc bgColor={color} key={"front"} isFront={isFront}>
            {frontLoading && previewF && (
              <TeeImage
                style={{ position: "absolute" }}
                src="/loading.gif"
                alt="กำลังโหลด..."
              />
            )}
            {previewF && <TeeImage src={previewF} onLoad={() => setFrontLoading(false)}></TeeImage>}
          </TeeSc>
          :
          <TeeSc bgColor={color} key={"back"} isFront={isFront}>
            {backLoading && previewB && (
              <TeeImage
                style={{ position: "absolute" }}
                src="/loading.gif"
                alt="กำลังโหลด..."
              />
            )}
            {previewB && <TeeImage src={previewB} onLoad={() => setBackLoading(false)}></TeeImage>}
          </TeeSc>
        }
      </MainItemSc>
      {imageName && <ItemDescription warp={isShowSwitchButton}>{imageName}</ItemDescription>}
      {price && <ItemDescription>{"฿" + (price + config.price || "xxx")}</ItemDescription>}
    </>
  );
};
export default ShopItem;

const MainItemSc = styled.div`
  position: relative;
`;
const SwitchSideSc = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
  width: 15%;
  height: 15%;
  margin: 5%;
  background-image: url('/flip.png');
  background-size: cover;
  display: flex;
  align-items: center;
  `;
// background-color: ${style.colors.mainDark1};
const TeeSc = styled.div`
  background-image: ${({ isFront }) => isFront ? `url("/font.png");` : `url("/back.png");`};
  background-color: ${({ bgColor }) => `${bgColor || "white"}`};
  background-size: cover;
  width: 100%;
  padding-top: 100%;
  margin: auto;
  position: relative;
  `;

const scale = 0.8;
const TeeImage = styled.img`
  position: absolute;
  background-size: cover;
  width: auto;
  padding-top: unset;
  width: ${40 * scale}%;
  height: ${50 * scale}%;
  display: block;
  left: ${(100 - 40 * scale) / 2}%;
  top: 25%;
`;
const ItemDescription = styled.span`
  margin: 5px;
  display: block;
  ${({ warp }) => !warp && `white-space: nowrap;`}
  overflow: hidden;
  text-overflow: ellipsis;
`;