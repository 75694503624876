import React, { useState } from "react";
import styled from "styled-components";

const Tabs = ({ setNewImage }) => {
  // const [,] = useState({ width: 0, height: 0 });
  const handleFileChange = (e) => {
    if (e.target.files.length) {
      setNewImage(e.target.files[0]);
      e.target.value = null;
    }
  };
  return (
    <TabHolderSc>
      <label htmlFor="upload-button">
        <span
          style={{
            color: "black",
            border: "solid black 1px",
            margin: "10px",
            padding: "10px",
          }}
        >
          Upload your photo
        </span>
      </label>
      <input
        type="file"
        id="upload-button"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </TabHolderSc>
  );
};

export default Tabs;


const TEMPLATE_MAX_WIDTH = 400;
const TabHolderSc = styled.div`
  margin: auto;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 20px;
  max-width: 890px;
  @media (min-width: 900px) {
    position: relative;
    max-width: ${TEMPLATE_MAX_WIDTH}px;
  }
  display: inline-block;
  vertical-align: top;
`;