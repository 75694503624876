import React, { useContext } from "react";
import ModalBase from "./ModalBase";
import Context from "../Context";
import CartItem from "./CartItem";

const ModalItemDetail = () => {
  const { hideModal } = useContext(Context.Modal);
  return (
    <ModalBase hide={hideModal} isFullWidth={true}>
      <CartItem hide={hideModal} />
    </ModalBase>
  );
};
export default ModalItemDetail;
