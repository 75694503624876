import styled from "styled-components";
import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from "react";
import { Stage, Layer } from "react-konva";
import KonvaImage from "./KonvaImage";
import Tabs from "./Tabs";
import IMAGE from "../constants/image";
const TEE_MAX_WIDTH = 400;
const RATIO = 0.35
const STAGE_MAX_WIDTH = TEE_MAX_WIDTH * RATIO;

const DesignTee = forwardRef((props, ref) => {
  const { setIsFront, imgItems, setIsShownBack, setImageName, imageName, saveBothSide, bg, showUpload } = props
  const [newImage, setNewImage] = useState(null);
  const [selectedId, selectShape] = useState(null);
  const [signalToSaveTs, setSignalToSaveTs] = useState("");
  const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
  const [canvasWidth, setCanvasWith] = useState(0);
  const lion = "https://konvajs.org/assets/lion.png";
  const canvas = useRef(null);
  const canvasFull = useRef(null);
  const templateRef = useRef(null);

  const [images, setImages] = useState([]
    // imgItems.map((x) => {
    //   return {
    //     url: lion,
    //     width: 100,
    //     height: 100,
    //     x: x * 50,
    //     y: x * 50,
    //     widthR: 100 / STAGE_MAX_WIDTH,
    //     heightR: 100 / STAGE_MAX_WIDTH,
    //     xR: (x * 50) / STAGE_MAX_WIDTH,
    //     yR: (x * 50) / STAGE_MAX_WIDTH,
    //     id: `lion${x}`,
    //     scaleX: 1,
    //     scaleY: 1,
    //     offsetX: 50,
    //     offsetY: 50,
    //     offsetXR: 50 / STAGE_MAX_WIDTH,
    //     offsetYR: 50 / STAGE_MAX_WIDTH,
    //     rotation: 0,
    //     fill: ["red", "green", "blue", "yellow"][x],
    //   };
    // })
  );

  //resize---------------------------------------------------
  useEffect(() => {
    const windowResize = () => {
      const newTeeWidth = templateRef.current.offsetWidth;
      const newCanvasWidth = newTeeWidth * RATIO;
      setCanvasWith(newCanvasWidth)
      setStageSize({ width: newCanvasWidth, height: newCanvasWidth * IMAGE.a4.hRatio });
      setImages(
        images.map((image) => {
          image.width = image.widthR * newCanvasWidth;
          image.height = image.heightR * newCanvasWidth;
          image.offsetX = image.width / 2;
          image.offsetY = image.height / 2;
          image.x = image.xR * newCanvasWidth;
          image.y = image.yR * newCanvasWidth;
          return image;
        })
      );
    };
    // const { dataUri, imageName, accessToken } = localStorage;
    // localStorage.removeItem(`dataUri_${ForB}`);
    // localStorage.removeItem(`imageName_${ForB}`);
    // if (dataUri && imageName && accessToken) {
    //   return callApi("designs/create", {
    //     dataUri,
    //     imageName,
    //   }).then(() => {
    //     history("/cart");
    //   });
    // }
    window.addEventListener("resize", windowResize);
    windowResize();
    return () => window.removeEventListener("resize", windowResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const width = templateRef.current.offsetWidth * RATIO;
    setStageSize({ width, height: width * IMAGE.a4.hRatio });
  }, [templateRef]);
  //---------------------------------------------------resize

  const flip = async () => {
    selectShape("");
    setIsFront(x => !x)
    setIsShownBack(true)
  }
  const signalToSave = () => {
    if (!imageName) {
      alert("โปรดตั้งชื่อรูป");
    } else {
      selectShape("")
      setSignalToSaveTs(+new Date())
    }
  }
  useEffect(() => signalToSaveTs && saveBothSide(), [signalToSaveTs])

  useImperativeHandle(ref, () => ({
    saveImage() {
      const preview = canvas.current.toDataURL()
      const printView = canvasFull.current.toDataURL()
      return preview ? [preview, printView] : ["", ""]
    }
  }))

  //upload---------------------------------------------------
  const [uploadedImage, setUploadedImage] = useState({ preview: "", raw: "" });
  const handleFileChange = (tempFile) => {
    if (tempFile) {
      var reader = new FileReader();
      reader.readAsDataURL(tempFile);
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function ({ path: [{ width, height }] }) {
          const ratio = width / height;
          const canvasHeight = canvasWidth * IMAGE.a4.hRatio;
          let x = 0;
          let y = 0;
          if (width > canvasWidth) {
            width = canvasWidth;
            height = width / ratio;
            x = width / 2;
            y = height / 2 + (canvasHeight - height) / 2;
          }
          if (height > canvasHeight) {
            height = canvasHeight;
            width = canvasHeight * ratio;
            x = width / 2 + (canvasWidth - width) / 2;
            y = height / 2;
          }
          setUploadedImage({
            preview: URL.createObjectURL(tempFile),
            raw: tempFile,
            props: {
              width,
              height,
              widthR: width / canvasWidth,
              heightR: height / canvasWidth,
              x,
              y,
              xR: x / canvasWidth,
              yR: y / canvasWidth,
              offsetX: width / 2,
              offsetY: height / 2,
              offsetXR: width / 2 / TEE_MAX_WIDTH,
              offsetYR: height / 2 / TEE_MAX_WIDTH,
            },
          });
        };
      };
    }
  };
  useEffect(() => {
    if (newImage) {
      handleFileChange(newImage);
      setNewImage(null);
    }
  }, [newImage, setNewImage]);
  useEffect(() => {
    if (uploadedImage.preview) {
      setImages((old) => [
        ...old,
        {
          url: uploadedImage.preview,
          id: String(+new Date()),
          scaleX: 1,
          scaleY: 1,
          rotation: 0,
          ...uploadedImage.props,
        },
      ]);
      setUploadedImage({});
    }
  }, [uploadedImage]);
  //---------------------------------------------------upload

  const filp = () => {
    if (selectedId) {
      const newImages = images.map((x) => {
        if (x.id === selectedId) {
          x.scaleX = x.scaleX * -1;
        }
        return x;
      });
      setImages(newImages);
    }
  };
  const rotate = () => {
    if (selectedId) {
      const newImages = images.map((x) => {
        if (x.id === selectedId) {
          x.rotation += 15;
        }
        return x;
      });
      setImages(newImages);
    }
  };
  const pushBack = () => {
    if (selectedId) {
      let target;
      const newImages = images.reverse().reduce((res, x, i) => {
        if (x.id === selectedId && i + 1 < images.length) {
          target = x;
        } else {
          res.push(x);
          if (target) {
            res.push(target);
            target = null;
          }
        }
        return res;
      }, []);
      setImages(newImages.reverse());
    }
  };
  const center = () => {
    if (selectedId) {
      const newImages = images.map((x) => {
        if (x.id === selectedId) {
          const teeWidth = templateRef.current.offsetWidth;
          const stageWidth = teeWidth * RATIO;
          x.x = stageWidth / 2;
          x.xR = 0.5;
        }
        return x;
      });
      setImages(newImages);
    }
  };
  const duplicate = () => {
    if (selectedId) {
      images.forEach((x) => {
        if (x.id === selectedId) {
          const teeWidth = templateRef.current.offsetWidth;
          const stageWidth = teeWidth * RATIO;
          setUploadedImage({
            preview: x.url,
            props: {
              ...x,
              x: stageWidth / 2,
              xR: 0.5,
              rotation: 0,
              id: String(+new Date()),
            },
          });
        }
      });
    }
  };
  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };
  const deletes = () => {
    if (selectedId) {
      const newImages = images.filter((x) => {
        return x.id !== selectedId;
      });
      setImages(newImages);
    }
  };
  return (
    <>
      <TeeSc ref={templateRef} bg={bg}>
        <SaveIconSc onClick={signalToSave} />
        <FlipIconSc onClick={flip} />
        <ToolsHolderSc>
          <ToolSc>
            <img
              alt="icon"
              src="/bin.png"
              onClick={deletes}
            />
          </ToolSc>
          <ToolSc>
            <img
              alt="icon"
              src="/copy.png"
              onClick={duplicate}
            />
          </ToolSc>
          <ToolSc>
            <img
              alt="icon"
              src="/center.png"
              onClick={center}
            />
          </ToolSc>
          <ToolSc>
            <img
              alt="icon"
              src="/layer.png"
              onClick={pushBack}
            />
          </ToolSc>
          <ToolSc>
            <img
              alt="icon"
              src="/flip.png"
              onClick={filp}
            />
          </ToolSc>
          <ToolSc>
            <img
              alt="icon"
              src="/rotate.png"
              onClick={rotate}
            />
          </ToolSc>
        </ToolsHolderSc>
        <KonvaStage
          style={{ display: "none" }}
          ref={canvasFull}
          {...{
            width: stageSize.width * 10,
            height: stageSize.height * 10,
          }}
        >
          <Layer>
            {images.map((rect, i) => {
              return (
                <KonvaImage
                  fullSize={true}
                  key={i}
                  shapeProps={rect}
                  isSelected={rect.id === selectedId}
                  onSelect={() => {
                    selectShape(rect.id);
                  }}
                  stageWidth={
                    templateRef.current
                      ? templateRef.current.offsetWidth * RATIO
                      : 160
                  }
                />
              );
            })}
          </Layer>
        </KonvaStage>
        <KonvaStage
          ref={canvas}
          {...stageSize}
          onMouseDown={checkDeselect}
          onTouchStart={checkDeselect}
        >
          <Layer>
            {images.map((rect, i) => {
              return (
                <KonvaImage
                  key={i}
                  shapeProps={rect}
                  isSelected={rect.id === selectedId}
                  onSelect={() => {
                    selectShape(rect.id);
                  }}
                  stageWidth={
                    templateRef.current
                      ? templateRef.current.offsetWidth * RATIO
                      : 160
                  }
                  onChange={(newAttrs) => {
                    const stageWidth = templateRef.current.offsetWidth * RATIO;
                    const rects = images.slice();
                    rects[i] = newAttrs;
                    rects[i].widthR = rects[i].width / stageWidth;
                    rects[i].heightR = rects[i].height / stageWidth;
                    rects[i].xR = rects[i].x / stageWidth;
                    rects[i].yR = rects[i].y / stageWidth;
                    setImages(rects);
                  }}
                />
              );
            })}
          </Layer>
        </KonvaStage>
        <input
          type="text"
          style={{
            textAlign: "center",
            margin: "auto",
            marginTop: "20px",
            display: "block",
            position: "absolute",
            left: "10%",
            width: "80%",
          }}
          value={imageName}
          onChange={(e) => setImageName(e.currentTarget.value)}
          placeholder="ชื่อแบบ"
        />
      </TeeSc>
      {showUpload &&
        <Tabs setNewImage={setNewImage} />
      }
    </>
  );
})

export default DesignTee;


const TeeSc = styled.div`
  margin: auto;
  position: relative;
  width: 100%;
  max-width: ${TEE_MAX_WIDTH}px;
  display: inline-block;
  background-image: url("/${({ bg }) => bg}");
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
  &:before {
    content: "";
    display: block;
    padding-top: ${IMAGE.template.hRatio * 100}%;
    padding-top: 100%;
  }
`;
// background-size: cover;

const ToolsHolderSc = styled.div`
  color: black;
  width: 10%;
  height: 50%;
  z-index: 9997;
  position: absolute;
  bottom: 12%;
  right: 10%;
`;
const ToolSc = styled.div`
  cursor: pointer;
  height: 20%;
  position: relative;
  & > div {
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  & > img {
    width: 80%;
    height: 80%;
    padding: 10%;
  }
`;
const FlipIconSc = styled.div`
  cursor: pointer;
  position: absolute;
  top: 5%;
  right: 10%;
  width: 10%;
  height: 10%;
  background-image: url("/flip.png");
  background-size: cover;
`;
const SaveIconSc = styled.div`
  cursor: pointer;
  position: absolute;
  top: 5%;
  left: 10%;
  width: 10%;
  height: 10%;
  background-image: url("/3099916.png");
  background-size: cover;
`;
const KonvaStage = styled(Stage)`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  & > div {
    margin: auto;
  }
  & > div > canvas {
    border: solid black 2px !important;
  }
`;
