const style = {
  colors: {
    mainDark1: "#79a0ab",
    mainDark2: "#5b7197",
    mainDark3: "#2A4C84",
    mainLight1: "#e3a494",
    mainLight2: "#eecfc2",
    mainLight3: "#e8e3dd",
    lightGray: "#eeeeee",
    gray: "#bcbcbc",
    darktGray: "#999999",
    textDark: "white",
    textWhite: "black",
    success: "#019035",
    danger: "darkred",
  },
  css: {
    lightShadow: "box-shadow: 0 0px 25px -5px black",
    shadow: "box-shadow: 0 0px 15px 5px black",
  },
  sizeGT: () => {
    return `@media (min-width: 768px) {

        }`;
  },
};

export default style;
