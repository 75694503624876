import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Context from "../Context";

const Logout = () => {
  const { callApi } = useContext(Context.Modal);
  const { profileState, setProfileState } = useContext(Context.Profile);
  const history = useNavigate();
  useEffect(() => {
    callApi("users/logout", profileState);
    setProfileState({});
    localStorage.removeItem("accessToken");
    history("/");
    console.log("it went to logout"); // eslint-disable-next-line
  }, []);
  return <></>;
};
export default Logout;
