import React, { useEffect, useState, useRef, useContext } from "react";
import style from "../constants/style";
import styled from "styled-components";
import Context from "../Context";
import SmallDetailedItem from "../components/SmallDetailedItem";
import { useParams, useNavigate } from "react-router-dom";
import { sum, sumPxQ, addressToHtmlString } from "../util"
import AddressString from "../components/AddressString"

const OrderDetail = () => {
  const { callApi } = useContext(Context.Modal);
  const { config } = useContext(Context.General);
  const { profileState, setProfileState } = useContext(Context.Profile);
  const { showModalAddress } = useContext(Context.Modal);

  const [totalPrice, setTotalPrice] = useState(0);
  const [orderToShow, setOrderToShow] = useState({});
  const [orderStatus, setOrderStatus] = useState("");
  const [slipName, setSlipName] = useState("");
  const [slipFile, setSlipFile] = useState({});
  const { orderId } = useParams();
  const [address, setAddress] = useState({})
  const slipInputRef = useRef({});
  const history = useNavigate();

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const base64data = reader.result;
      base64data.substr(base64data.indexOf(",") + 1);
      cb(base64data);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const setInitState = async () => {
    const order = (await callApi("orders/" + orderId)).data;
    setOrderStatus(order.status);

    let tempPrice = 0;
    let tempOrderToShow = {};

    for (const [designId, design] of Object.entries(order.designs)) {
      design.items.forEach((x) => {
        tempOrderToShow[designId + x.color] = tempOrderToShow[
          designId + x.color
        ] || {
          previewF: design.previewF,
          previewB: design.previewB,
          designId: design.disignId,
          items: [],
        };
        tempOrderToShow[designId + x.color].items.push(x);
      });
      tempPrice += sumPxQ(design.items, config.price)
    }
    if (Object.values(tempOrderToShow).length && config.price) {
      setTotalPrice(tempPrice);
    }
    setAddress(order.address)
    setOrderToShow(tempOrderToShow);
  }
  useEffect(() => {
    setInitState()
  }, []);
  useEffect(() => {
    if (Object.values(orderToShow).length && config.price) {
      setTotalPrice(sum(Object.values(orderToShow).map(x => sumPxQ(x.items, config.price))))
    }
  }, [config]);
  return (
    <FlexHolder>
      <ItemsHolder>
        <h2 style={{ marginTop: "0" }}>{orderStatus.toLocaleUpperCase()}</h2>
        {orderStatus === "pending" &&
          <EditFloatRight onClick={() => {
            showModalAddress({
              ...{
                addressList: address.string ?
                  { [address.string]: address } :
                  {}

              },
              orderId,
              callBack: setInitState
            })
          }}>แก้ไขที่อยู่</EditFloatRight>
        }
        <AddressString {...address} />
        {orderStatus !== "pending" &&
          <FloatLeft
            onClick={() => callApi("orders/processBack", {
              orderIds: [orderId],
            }).then(() => window.location.reload())}>{"<<"}</FloatLeft>
        }
        {orderStatus !== "delivered" &&
          <FloatRight
            onClick={() => callApi("orders/processNext", {
              orderIds: [orderId],
            }).then(() => window.location.reload())}>{">>"}</FloatRight>
        }
        <b><p>
          {
            {
              pending: "โปรดชำระเงินและแนบสลิปเพื่อดำเนินการต่อ",
              paid: "แจ้งการชำระเงินเรียบร้อย",
              verified: "แจ้งการชำระเงินเรียบร้อย",
              processing: "กำลังดำเนินการสกรีนเสื้อ",
              shipping: "กำลังจัดส่งสินค้า",
              delivered: "สินค้าถูกจัดส่งถึงที่หมายเรียบร้อย",
            }[orderStatus]
            // config?.status?.[orderStatus] || ""
          }
        </p></b>
        {Object.keys(orderToShow).map((x, i) => (
          <div key={i}>
            <SmallDetailedItem key={i} order={orderToShow[x]} />
            {Object.keys(orderToShow).length !== i + 1 && <hr />}
          </div>
        ))}
      </ItemsHolder>
      {orderStatus && <PaddingBankInfoSc status={orderStatus} />}
      <ProceedWarperSc>
        {orderStatus === "pending" && (
          <RightWarperSC>
            <BankDetail>
              <img src="/img/square.png" alt={"bankLogo"} height={"100px"} />
              <div>
                <h3 style={{ margin: "0.5em" }}>BankName</h3>
                <h3 style={{ margin: "0.5em" }}>01234567890</h3>
                <h4 style={{ margin: "0.5em" }}>Phuriphat Petdee</h4>
              </div>
              <div style={{ textAlign: "right" }}>
                <button style={{ diplay: "inline-block" }}>
                  ยกเลิกคำสั่งซื้อ
                </button>
                <button
                  style={{ diplay: "inline-block" }}
                  onClick={() => callApi("orders/editPending", { orderId }).then(() => history("/cart"))}
                >
                  แก้ไข
                </button>
              </div>
            </BankDetail>
            <ProceedSc>
              <MakeOrderSc onClick={() => slipInputRef.current.click()}>
                แนบสลิป
              </MakeOrderSc>
              <PriceSc style={{ color: "black" }}>
                {slipName.length > 15 ? "..." + slipName.slice(-15) : slipName}
                <input
                  ref={slipInputRef}
                  style={{ display: "none" }}
                  src="/img/square.png"
                  type="file"
                  onChange={(e) => {
                    getBase64(e?.target?.files[0], (base64Img) => {
                      setSlipFile(base64Img);
                    });
                    // setSlipFile(e.currentTarget.files[0]);
                    setSlipName(e.currentTarget.value?.split("\\").pop());
                  }}
                />
              </PriceSc>
            </ProceedSc>
            <ProceedSc>
              <PriceSc>รวม: {totalPrice.toLocaleString()}</PriceSc>
              <MakeOrderSc
                disabled={
                  !slipName || !Object.values(address).length
                }
                onClick={async () => {
                  const uploadResult = await callApi("orders/confirm", {
                    slipFile,
                    slipName,
                    orderId,
                  });
                  if (uploadResult.data.status === "success") {
                    setOrderStatus("paid");
                  }
                }}
              >
                ยืนยันการชำระเงิน
              </MakeOrderSc>
            </ProceedSc>
          </RightWarperSC>
        )}
      </ProceedWarperSc>
    </FlexHolder >
  );
};
export default OrderDetail;

const MockAdmin = styled.div`
  padding: 1px 5px 1px 5px;
  top: 10px;
  position: absolute;
  width: 40px;
  border: solid black 1px;
`
const FloatLeft = styled(MockAdmin)`
  left: 10px;
`
const FloatRight = styled(MockAdmin)`
  right: 10px;
`
const EditFloatRight = styled(MockAdmin)`
  width: auto;
  right: 10px;
  top: unset;
`
const FlexHolder = styled.div`
  display: block;
  @media (min-width: 660px) {
    display: flex;
  }
`;
const ItemsHolder = styled.div`
  text-align: center;
  background: ${style.colors.mainLight3};
  width: auto;
  padding: 10px;
  display: block;
  position: relative;
  @media (min-width: 660px) {
    flex: 2;
  }
`;
const PaddingBankInfoSc = styled.div`
  @media (max-width: 660px) {
    height: 270px;
    display: ${(props) => (props.status === "pending" ? "block" : `none`)};
  }
`;
const ProceedWarperSc = styled.div`
  @media (max-width: 660px) {
    background: ${style.colors.mainLight3};
    position: fixed;
    margin: 0;
    width: 100%;
    z-index: 9998;
    bottom: 0px;
    left: 0;
    ${style.css.lightShadow}
  }
  @media (min-width: 660px) {
    flex: 1;
    position: relative;
  }
`;
const RightWarperSC = styled.div`
  width: 100%;
  @media (min-width: 660px) {
    display: flex;
    justify-content: flex-end;
  }
`;
const ProceedSc = styled.div`
  color: red;
  @media (max-width: 660px) {
    display: flex;
  }
  @media (min-width: 660px) {
    display: block;
    position: fixed;
    z-index: 9998;
    bottom: 20px;
  }
`;
const BankDetail = styled.div`
  padding: 10px;
  display: flex;
  @media (max-width: 660px) {
  }
  @media (min-width: 660px) {
  }
`;
const PriceSc = styled.h3`
  margin: 10px;
  @media (max-width: 660px) {
    padding: 10px;
    text-align: center;
    flex: 1;
  }
  @media (min-width: 660px) {
    text-align: right;
  }
`;
const MakeOrderSc = styled.button`
  background-color: ${(props) =>
    props.disabled ? style.colors.gray : style.colors.success};
  margin: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  @media (max-width: 660px) {
    flex: 1;
  }
  @media (min-width: 660px) {
    padding: 15px 30px;
    width: 100%;
    float: right;
  }
`;