import React, { useContext } from "react";
import ModalBase from "./ModalBase";
import Context from "../Context";
import AddressInputs from "./AddressInputs";
import { updateAddress } from "../util/address"

const ModalAddress = () => {
  const { hideModal, modalAddress, callApi } = useContext(Context.Modal);
  const { profileState, setProfileState } = useContext(Context.Profile);
  const initString = Object.keys(modalAddress.addressList).map(string => {
    modalAddress.addressList[string].isDefault = true
    return string
  })[0]
  const addressList = { ...modalAddress.addressList, ...profileState.addressList }
  return (
    <ModalBase hide={hideModal} isFullWidth={true} style={{ overflow: "scroll" }}>
      <AddressInputs style={{ overflow: "scroll" }}
        hide={hideModal}
        addressList={addressList}
        callBack={modalAddress.callBack}
        initString={initString}
        orderId={modalAddress.orderId}
        handleSave={async (data, type) => {
          hideModal()
          return await updateAddress(data, callApi, setProfileState, type)
        }}
      />
    </ModalBase>
  );
};
export default ModalAddress;
