import React, { useContext, useEffect, useState } from "react";
import { updateAddress } from "../util/address";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Context from "../Context";
import AddressInput from "../components/AddressInputs"

const Profile = () => {
  const { callApi } = useContext(Context.Modal);
  const { profileState, setProfileState } = useContext(Context.Profile);
  const history = useNavigate();

  useEffect(() => {
    (async () => {
      const code = new URLSearchParams(window.location.search).get("code");
      console.log("🚀 ~ file: Profile.js ~ line 41 ~ code", code)
      if (code) {
        localStorage.removeItem("accessToken");
        const lineLoginResult = await callApi("users/lineLogin", { code });

        if (lineLoginResult.data.accessToken) {
          localStorage.setItem("accessToken", lineLoginResult.data.accessToken);
          setProfileState(lineLoginResult.data.user);
          if (localStorage.getItem("redirect")) {
            const redirect = localStorage.getItem("redirect");
            console.log("🚀 ~ file: Profile.js ~ line 28 ~ redirect", redirect, !!redirect)
            localStorage.removeItem("redirect");
            history(redirect);
          }
        }
      }
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {profileState.name && (
        <div style={{ textAlign: "center" }}>
          <h2 style={{ marginTop: "0" }}>ข้อมูลสมาชิก</h2>
          <h3>รหัสสมาชิก: {profileState.userNumber}</h3>
          <AddressInput
            addressList={profileState.addressList || {}}
            handleSave={async (data) => {
              return await updateAddress(data, callApi, setProfileState)
            }} />
        </div>
      )
      }
    </>
  );
};

export default Profile;
