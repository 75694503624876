import React, { useContext, useEffect, useState } from "react";
import style from "../constants/style";
import styled from "styled-components";
import Context from "../Context";
import CartItem from "../components/CartItem";
import { useNavigate } from "react-router-dom";
import { sum, sumPxQ } from "../util"

const Cart = () => {
  const history = useNavigate();
  const { callApi } = useContext(Context.Modal);
  const [sumPrice, setSumPrice] = useState(0);
  const [sumArray, setSumArray] = useState([]);
  const [cart, setCart] = useState({});

  useEffect(() => {
    (async () => {
      if (localStorage.accessToken) {
        const cart = await callApi("orders/cart");
        const showCart = cart.data.designs || {};
        setCart(showCart);
      }
    })(); // eslint-disable-next-line
  }, []);
  useEffect(() => {
    let tempSumArray = [];
    for (const [, value] of Object.entries(cart)) {
      tempSumArray.push(sumPxQ(value.items));
    }
    setSumArray(tempSumArray)
  }, [cart])
  useEffect(() => setSumPrice(sum(sumArray)), [sumArray])
  return (
    <FlexHolder>
      {Object.keys(cart).length ? (
        <>
          <ItemsHolder>
            {Object.keys(cart)
              .sort()
              .map((designId, i) => (
                <CartItem
                  design={cart[designId]}
                  key={i}
                  index={i}
                  setSumArray={setSumArray}
                  setSumPrice={setSumPrice}
                />
              ))}
          </ItemsHolder>
          <ProceedWarperSc>
            <RightWarperSC>
              <ProceedSc>
                <PriceSc>ราคารวม: {sumPrice.toLocaleString()}</PriceSc>
                <MakeOrderSc
                  onClick={async () => {
                    const orderId = (await callApi("orders", cart)).data;
                    history("/orders/" + orderId);
                  }}
                >
                  สั่งซื้อ
                </MakeOrderSc>
              </ProceedSc>
            </RightWarperSC>
          </ProceedWarperSc>
        </>
      ) : (
        <ItemsHolder>ไม่มีสินค้าในตะกร้า</ItemsHolder>
      )}
    </FlexHolder>
  );
};
export default Cart;

const FlexHolder = styled.div`
  display: block;
  @media (min-width: 660px) {
    display: flex;
  }
`;
const ItemsHolder = styled.div`
  text-align: center;
  background: ${style.colors.mainLight3};
  width: auto;
  padding: 10px;
  display: block;

  @media (max-width: 660px) {
    padding-bottom: 70px;
  }
  @media (min-width: 660px) {
    flex: 2;
  }
`;
const ProceedWarperSc = styled.div`
  @media (max-width: 660px) {
    background: ${style.colors.mainLight3};
    position: fixed;
    margin: 0;
    width: 100%;
    z-index: 9998;
    bottom: 0px;
    left: 0;
    ${style.css.lightShadow}
  }
  @media (min-width: 660px) {
    flex: 1;
    position: relative;
  }
`;
const RightWarperSC = styled.div`
  width: 100%;
  @media (min-width: 660px) {
    display: flex;
    justify-content: flex-end;
  }
`;
const ProceedSc = styled.div`
  color: red;
  @media (max-width: 660px) {
    display: flex;
  }
  @media (min-width: 660px) {
    display: block;
    position: fixed;
    z-index: 9998;
    bottom: 20px;
  }
`;
const PriceSc = styled.h3`
  margin: 10px;
  @media (max-width: 660px) {
    padding: 10px;
    text-align: center;
    flex: 1;
  }
  @media (min-width: 660px) {
  }
`;
const MakeOrderSc = styled.button`
  background-color: ${style.colors.success};
  margin: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  @media (max-width: 660px) {
    flex: 1;
  }
  @media (min-width: 660px) {
    padding: 15px 30px;
    width: 100%;
    float: right;
  }
`;