import React from 'react';
import style from '../constants/style'
import styled from 'styled-components';

const Main = styled.div`
  border-radius: 10px;
  margin: 15px;
  padding: 5px 10px;
  display: inline-block;
  background-color: ${style.colors.mainDark3};
  color: white;
  cursor: pointer;
  :hover{
    background-color: ${style.colors.mainDark2};
  }
  :active{
    background-color: ${style.colors.mainDark1};
  }
`
const Button = ({children, handleClick}) =>{
  return (
  <Main onClick={handleClick}>
    {children}
  </Main>
  );
}
export default Button;
