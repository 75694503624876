import React, { useEffect, useState, useContext } from "react";
import Context from "../Context";
// import style from "../constants/style";
// import styled from "styled-components";
// import callApi from "../util/callApi";
import { useNavigate } from "react-router-dom";

const Orders = () => {
  const { callApi } = useContext(Context.Modal);
  const { config } = useContext(Context.General);
  const [orderList, setOrderList] = useState([]);
  const history = useNavigate();
  const [isLastCallApiFound, setIsLastCallApiFound] = useState(true);
  const [lastOrderId, setLastOrderId] = useState("");
  const getMoreOrder = async (body = {}) => {
    body.limit = 10;
    const orders = (await callApi("orders/list", body)).data;
    if (orders.length) {
      setLastOrderId(orders.slice(-1)[0].id);
    }
    setIsLastCallApiFound(orders.length === body.limit);
    let tempOrderList = {};
    orders.forEach((order) => {
      tempOrderList[order.id] = {
        price: 0,
        quantity: 0,
        status: order.status,
        orderAt: order.orderAt,
      };
      Object.values(order.designs).forEach((design) => {
        design.items.forEach((item) => {
          tempOrderList[order.id].price += item.quantity * (item.price + config.price);
          tempOrderList[order.id].quantity += item.quantity;
        });
      });
    });
    setOrderList((oldOrderList) => {
      return { ...oldOrderList, ...tempOrderList };
    });
    // console.log(tempOrderList);
    // setOrderList(tempOrderList);
  };
  useEffect(() => {
    (async () => {
      if (localStorage.accessToken) {
        getMoreOrder();
      }
    })();
    // eslint-disable-next-line
  }, []);
  return (
    <div style={{ textAlign: "center" }}>
      {Object.keys(orderList).map((orderId, i) => {
        return (
          <div
            style={{
              padding: "10px",
              margin: "10px",
              border: "1px solid black",
              textAlign: "center",
            }}
            key={i}
            onClick={() => history("/orders/" + orderId)}
          >
            <span>
              {new Date(orderList[orderId].orderAt).toLocaleString()} --{" "}
            </span>
            <span>{orderList[orderId].quantity} ตัว </span>
            <span>{orderList[orderId].price} บาท </span>
            <span>{orderList[orderId].status}</span>
          </div>
        );
      })}
      {Object.keys(orderList).length
        ? isLastCallApiFound && (
            <button onClick={() => getMoreOrder({ startAfter: lastOrderId })}>
              Load More
            </button>
          )
        : "คุณยังไม่เคยมีคำสั่งซื้อ"}
    </div>
  );
};
export default Orders;
